import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { CancelButton, PrimaryButton } from '../../../../components/UIKit';
import { AccessRole } from '../../../SettingProfile/components';
import { EDIT_USER_ACCESS_MODAL_ID, closeModalWindow } from '../../../ModalWindow/slice';
import ModalWindow from '../../../ModalWindow';
import i18n from '../../../../i18n';
import { getEditUserAccessModalSelector } from '../../../../redux-store/selectors/modals';

const formID = 'editUserAccessFormId';

const EditAccessModal = (props) => {
  const { handleSubmit, pristine, reset, initialize } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getEditUserAccessModalSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: EDIT_USER_ACCESS_MODAL_ID }));

  React.useEffect(() => {
    if (modal?.data?.permission) {
      initialize({ access: modal?.data?.permission });
    }

    if (!modal?.open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const submitModal = (e) => {
    e.preventDefault();

    handleSubmit({ permission: e.target.elements.access.value, userId: modal?.data?._id });
    handleOnClose();
  };

  return (
    <ModalWindow modalID={EDIT_USER_ACCESS_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editUser')}</h5>
      </div>
      <div>
        <div className="modal-body">
          <form
            id={formID}
            onSubmit={submitModal}
            className="m-login__form m-form pop-up-form add-sm-us"
          >
            <div className="notification-type-error-container role-type mb-4">
              <AccessRole customFieldName="access" />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton form={formID} disabled={pristine} isSubmit type="send" />
        </div>
      </div>
    </ModalWindow>
  );
};

EditAccessModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'editUserAccessFormId'
})(EditAccessModal);

export default form;
