import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ModalWindow from '../../containers/ModalWindow';
import { closeModalWindow } from '../../containers/ModalWindow/slice';
import { PrimaryButton } from '../../components/UIKit';

import i18n from '../../i18n';
import './index.scss';

const DeviceLimitPopUp = ({ modalID }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalWindow({ modalID }));
  };

  return (
    <ModalWindow isModalOver customOnClose={handleClose} modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('planLimitReachedTitle')}</h5>
      </div>

      <div>
        <div className="modal-body">
          {i18n.t('planLimitReachedDesc')}
        </div>
        <div className="modal-footer">
          <PrimaryButton onClickHandler={handleClose} i18nLabel="ok" />
        </div>
      </div>
    </ModalWindow>
  );
};

DeviceLimitPopUp.propTypes = {
  modalID: PropTypes.string.isRequired
};

export default DeviceLimitPopUp;
