import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { omitBy } from 'lodash';
import PropTypes from 'prop-types';
import { getDataListSelector } from '../../redux-store/selectors/dataList';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { setPagesSettingColumnsWidth, setPagesSettingQueries } from '../../containers/PagesSettings/slice';
import { dataListSendRequest } from '../../containers/DataList/slice';
import { ReactTableUI } from './ReactTableUI';

import './index.scss';

const removeEmptyValuesFromQueries = (values) => omitBy(values, (value) => value === '');

export function ReactTableV2({
  columns = [],
  listID = 'default',
  defaultSorting = 'last_name:asc',
  listURL = '',
  transformResponse = (d) => d,
  SubComponent = undefined,
  defaultParams = {},
  params: paramsProp = {},
  savePageColumnWidth = undefined,
  columnVisibility = {},
  setRowSelection = undefined,
  rowSelection = undefined,
  data = undefined,
  isHideThead = false,
  tableStyles = {},
  getTrProps
}) {
  const dispatch = useDispatch();
  const tableRef = useRef();

  const { [listID]: dataList = {} } = useSelector(getDataListSelector);
  const { [listID]: settings = {} } = useSelector(getPagesSettingsSelector);

  const loading = dataList.loading || false;

  const { _sort = defaultSorting } = settings?.queries || {};
  const [sortField, sortDirection] = _sort.split(':');

  const handleSort = (sorting) => {
    const [{ id: sortId }] = sorting();
    const isDesc = sortDirection === 'asc';
    const newSort = `${sortId}:${isDesc ? 'desc' : 'asc'}`;
    const params = {
      listURL,
      params: removeEmptyValuesFromQueries({ ...paramsProp, ...settings.queries, _sort: newSort }),
      transformResponse
    };

    dispatch(dataListSendRequest({ listID, dataList: params }));

    dispatch(setPagesSettingQueries({ page: listID, data: { _sort: newSort } }));
  };

  useEffect(() => {
    if (listID && listURL) {
      const params = {
        listURL,
        params: removeEmptyValuesFromQueries({ ...paramsProp, ...defaultParams, ...settings.queries }),
        transformResponse
      };
      dispatch(dataListSendRequest({ listID, dataList: params }));
    }
  }, [listID, listURL]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => {
    if (tableRef.current) {
      const sizes = tableRef.current.getFlatHeaders().reduce((acc, header) => {
        acc[header.id] = header.getSize();
        return acc;
      }, {});

      if (savePageColumnWidth) {
        savePageColumnWidth(sizes);
      } else if (settings?.width) {
        const newWidth = { ...settings.width, ...sizes };
        dispatch(setPagesSettingColumnsWidth({ page: listID, data: newWidth }));
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReactTableUI
      columns={columns}
      dataList={data ?? dataList}
      settings={settings}
      sortField={sortField}
      sortDirection={sortDirection}
      handleSort={handleSort}
      SubComponent={SubComponent}
      tableRef={tableRef}
      loading={loading}
      columnVisibility={columnVisibility}
      setRowSelection={setRowSelection}
      rowSelection={rowSelection}
      isHideThead={isHideThead}
      tableStyles={tableStyles}
      getTrProps={getTrProps}
    />
  );
}

ReactTableV2.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  listID: PropTypes.string.isRequired,
  defaultSorting: PropTypes.string,
  listURL: PropTypes.string,
  transformResponse: PropTypes.func,
  SubComponent: PropTypes.func,
  defaultParams: PropTypes.instanceOf(Object),
  params: PropTypes.instanceOf(Object),
  savePageColumnWidth: PropTypes.func,
  columnVisibility: PropTypes.instanceOf(Object),
  setRowSelection: PropTypes.func,
  rowSelection: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  isHideThead: PropTypes.bool,
  tableStyles: PropTypes.instanceOf(Object),
  getTrProps: PropTypes.func
};
