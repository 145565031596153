const replace = {
  changeSMID: "Modifier l'ID du Rennergy OEM",
  reqSMID: 'Rennergy ID *',
  SMID: 'Rennergy ID',
  SMIDTolltip: 'L\'ID Rennergy est un identifiant unique de votre Rennergy. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: 'Rennergy ID',
  smidLabel: 'Rennergy ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le Rennergy ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du Rennergy. La passerelle n\'est pas supprimée.'
};

export default replace;
