const replace = {
  changeSMID: 'Cambia l\'ID Rennergy OEM',
  reqSMID: 'Rennergy ID *',
  SMID: 'Rennergy ID',
  SMIDTolltip: 'Il Rennergy ID è un identificatore unico del tuo dispositivo. Questo ID si trova sul dispositivo sotto il codice QR.',
  enableSmId: 'Rennergy ID',
  smidLabel: 'Rennergy ID',
  confirmDeletingDesc: 'Conferma la cancellazione di tutti i dati di questo gateway inserendo l\'ID Rennergy. Dopo la conferma, questo processo non può essere invertito.',
  confirmDeletingError: 'L\'ID non corrisponde all\'ID Rennergy. Il gateway non è stato cancellato.'
};

export default replace;
