import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

export const Switcher = (props) => {
  const { input, defaultValue = undefined, onColor = '#A3238E', ...custom } = props;

  useEffect(() => {
    if (!input.value && typeof defaultValue !== 'undefined') {
      input.onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Switch
      checked={Boolean(input.value)}
      onChange={input.onChange}
      onColor={onColor}
      height={24}
      {...custom}
    />
  );
};

Switcher.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  onColor: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};
