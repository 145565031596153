const replace = {
  changeSMID: 'Change Rennergy ID',
  reqSMID: 'Rennergy ID *',
  SMID: 'Rennergy ID',
  SMIDTolltip: 'The Rennergy ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'Rennergy ID',
  smidLabel: 'Rennergy ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the Rennergy ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the Rennergy ID. The gateway is not deleted.'
};

export default replace;
