const replace = {
  changeSMID: 'Ændre Rennergy ID',
  reqSMID: 'Rennergy ID *',
  SMID: 'Rennergy ID',
  SMIDTolltip: 'The Rennergy ID er en unik identifikator for din enhed. Du finder dette ID på enheden under QR-koden.',
  enableSmId: 'Rennergy ID',
  smidLabel: 'Rennergy ID',
  confirmDeletingDesc: 'Bekræft sletningen af ​​alle data fra denne gateway ved at indtaste Rennergy ID. Efter bekræftelse kan denne proces ikke vendes.',
  confirmDeletingError: 'ID\'et stemmer ikke overens med Rennergy-ID\'et. Gateway\'en er ikke slettet.'
};

export default replace;
